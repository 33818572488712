import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import ForgotPassword from '../Pages/Authentication/ForgotPassword';
import Login from '../Pages/Authentication/Login';
import Home from '../Pages/Home';
import MainHome from '../Pages/LandingPages/MainHome';
import AuthContext from '../Store/auth-context';

const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    let isLoggedIn = authCtx.isLoggedIn;

    console.log(authCtx)

    if (!isLoggedIn) {
        return (
            <div>
                <Routes>
                    <Route path="*" element={<Home />}></Route>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                </Routes>
            </div>
        )
    } else {
        return (
            <div>
                <Routes>
                    <Route path="*" element={<MainHome />}></Route>
                    <Route path="/" element={<MainHome />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                </Routes>
            </div>
        )
    }


}

export default MainNavigator