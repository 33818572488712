import React, { useState } from "react";

const AuthContext = React.createContext({
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    userIdentifier: localStorage.getItem("userIdentifier"),
    clientData: localStorage.getItem("clientData"),
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userIdentifier, setUserIdentifier] = useState(localStorage.getItem('userIdentifier'));
    const [clientData, setClientData] = useState(localStorage.getItem('clientData'))

    const userIsLoggedIn = !!token;

    const loginHandler = (token, userIdentifier, clientData) => {
        localStorage.setItem("token", token);
        localStorage.setItem("userIdentifier", userIdentifier);
        localStorage.setItem("clientData", clientData);
        setToken(token);
        setUserIdentifier(userIdentifier)
        setClientData(clientData)
    }

    const logoutHandler = () => {
        setToken(null);
        setUserIdentifier(null)
        setClientData(null)
        localStorage.removeItem("token");
        localStorage.removeItem("userIdentifier");
        localStorage.removeItem("clientData");
    }

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        userIdentifier: userIdentifier,
        clientData: clientData,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;